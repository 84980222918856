import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

:root {
    --grey10: #FBFCFD;
    --grey100: #F2F4F8;
    --grey200: #DDE1E6;
    --grey300: #C1C7CD;
    --grey400: #A2A9B0;
    --grey500: #878D96;
    --grey600: #697077;
    --grey700: #4D5358;
    --grey800:  #343A3F;
    --grey900: #21272A;
    --grey990: #121619;
}

html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!
}

body {
    background: white;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    max-width: 192rem;
    margin: 0 auto;
}

button, a {
    background: none;
    border: none;
    text-decoration: none;

    &:visited {
        color: inherit;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

p {
    margin: 0;
}

* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-weight: 400;
  word-break: keep-all;
}
`;
