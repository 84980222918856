import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./i18n/i18n";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { GlobalStyle } from "./components/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

const loading = <></>;

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <I18nextProvider i18n={i18n}>
    <RecoilRoot>
      <GlobalStyle />
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/404" element={<Page404 />} />
            <Route path="/500" element={<Page500 />} />
            <Route path="/*" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  </I18nextProvider>
);
