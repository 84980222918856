import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enCommon from "./resources/en/common.json";
import enHome from "./resources/en/home.json";
import enCubefarm from "./resources/en/cubefarm.json";
import enEunhwago from "./resources/en/eunhwago.json";
import enNewsroom from "./resources/en/newsroom.json";
import enFieldtrip from "./resources/en/fieldtrip.json";
import koCommon from "./resources/ko/common.json";
import koHome from "./resources/ko/home.json";
import koCubefarm from "./resources/ko/cubefarm.json";
import koEunhwago from "./resources/ko/eunhwago.json";
import koNewsroom from "./resources/ko/newsroom.json";
import koFieldtrip from "./resources/ko/fieldtrip.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        common: enCommon,
        home: enHome,
        cubefarm: enCubefarm,
        eunhwago: enEunhwago,
        newsroom: enNewsroom,
        fieldtrip: enFieldtrip,
      },
      ko: {
        common: koCommon,
        home: koHome,
        cubefarm: koCubefarm,
        eunhwago: koEunhwago,
        newsroom: koNewsroom,
        fieldtrip: koFieldtrip,
      },
    },
    supportedLngs: ["en", "ko"],
    fallbackLng: "en",
    ns: ["common", "home", "cubefarm", "eunhwago", "newsroom", "fieldtrip"],
    defaultNS: "common",
    interpolation: { escapeValue: false },
    react: {
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ["mark", "br", "strong", "span"],
    },
  });

export default i18n;
